var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mx-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-1",
                          arg: "collapse-1",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                      attrs: { title: _vm.getDefaultFilterMessage() },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n        Filtra\n      "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _vm.canVerb(_vm.resource, "store")
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-create",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea",
                          },
                          on: { click: _vm.onAdd },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "plus-circle",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v("\n        Crea"),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { type: "button", variant: "lisaweb", size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.openUrl(3)
                        },
                      },
                    },
                    [_vm._v("\n        Carica Scansioni\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { type: "button", variant: "lisaweb", size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.openUrl(2)
                        },
                      },
                    },
                    [_vm._v("\n        Report\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { type: "button", variant: "lisaweb", size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.openUrl(1)
                        },
                      },
                    },
                    [_vm._v("\n        Errori\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-collapse", { attrs: { visible: "", id: "collapse-1" } }, [
            _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.onSearch(_vm.filterName)
                      },
                    },
                  },
                  [
                    _c(
                      "b-card",
                      { staticClass: "filter" },
                      [
                        _c("b-row", [
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-input", {
                                attrs: {
                                  name: "RFRNAME",
                                  type: "text",
                                  label: "Nominativo",
                                  placeholder: "Inserisci un nominativo",
                                },
                                model: {
                                  value: _vm.filter.byRegistry.title,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter.byRegistry,
                                      "title",
                                      $$v
                                    )
                                  },
                                  expression: "filter.byRegistry.title",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-select", {
                                attrs: {
                                  name: "GDPR",
                                  type: "text",
                                  label: "Stato Raccolta Privacy",
                                  options: _vm.statusPrivacyOptions,
                                },
                                model: {
                                  value: _vm.filter.byAttribute.GDPR,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter.byAttribute,
                                      "GDPR",
                                      $$v
                                    )
                                  },
                                  expression: "filter.byAttribute.GDPR",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-select", {
                                attrs: {
                                  name: "elenca",
                                  label: "Stato Portafoglio",
                                  options: _vm.statusInsuranceOptions,
                                  taggable: false,
                                  multiple: false,
                                  closeOnSelect: true,
                                },
                                model: {
                                  value: _vm.filter.byInsuranceStatus.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter.byInsuranceStatus,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "filter.byInsuranceStatus.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form-group col-md-3 align-self-end",
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle:collapse-1",
                                      arg: "collapse-1",
                                    },
                                  ],
                                  attrs: {
                                    type: "submit",
                                    variant: "lisaweb",
                                    size: "sm",
                                  },
                                },
                                [_vm._v("Cerca")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn-reset",
                                  attrs: {
                                    type: "button",
                                    variant: "lisaweb",
                                    size: "sm",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClearFilter(_vm.filterName)
                                    },
                                  },
                                },
                                [_vm._v("Reset")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("table-registries", {
            ref: _vm.tableRef,
            attrs: {
              fields: _vm.fields,
              repository: _vm.repository,
              resource: _vm.resource,
              filterName: _vm.filterName,
              filterOn: {
                byRelations: [
                  "byRegistryGroup",
                  "byInsurancePolicy",
                  "byDocument",
                ],
              },
              onlyActions: ["edit", "infomodal", "destroy"],
              noCustom: "",
              noActions: "",
            },
            on: {
              edit: _vm.onEdit,
              destroy: _vm.onDestroy,
              rowSelector2: _vm.onPrint,
              rowSelector3: _vm.onDownload,
            },
          }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }